import { CONTENT_TYPES } from "skCommon/core/http";
import { Client } from "skCommon/api/client/simple";
import { SPACEKNOW_OAUTH } from "skCommon/auth/authenticator";

export class InsightsApiClient extends Client {
    public readonly api = "insights-api";
    public readonly authType = SPACEKNOW_OAUTH;
    public contentType = CONTENT_TYPES.JSON;

    public geojsondbProjectsList(region: string): Promise<object> {
        return this.call<object>({
            endpoint: "/geojsondb/projects/list",
            body: {
                region,
            },
        }).promise;
    }
    public geojsondbProjectsCategorization(project: string, date: string): Promise<object> {
        return this.call<object>({
            endpoint: "/geojsondb/projects/categorization",
            body: {
                project, date,
            },
        }).promise;
    }
    public healthchecksTypes(): Promise<object> {
        return this.call<object>({
            endpoint: "/healtchecks/types",
            body: {},
        }).promise;
    }
    public healthchecksList(productType: string, recordType: string): Promise<object> {
        return this.call<object>({
            endpoint: "/healtchecks/list",
            body: {
                productType, recordType,
            },
        }).promise;
    }
    public deliveryRecordsList(): Promise<object> {
        return this.call<object>({
            endpoint: "/delivery-records/list",
            body: {},
        }).promise;
    }
    public deliveryRecordsUpdate(record: object): Promise<object> {
        return this.call<object>({
            endpoint: "/delivery-records/update",
            body: record,
        }).promise;
    }
    public fillConfigsList(mapType: string): Promise<object> {
        return this.call<object>({
            endpoint: "/fill-configs/list",
            body: {mapType},
        }).promise;
    }
}

let client: InsightsApiClient;

export function getInsightsApiClient() {
    if (!client) {
        client = new InsightsApiClient();
    }

    return client;
}
