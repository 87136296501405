import { SkError } from "skCommon/core/error";
import {
    TaskedResult,
    TaskedClientConstructorOptions,
} from "skCommon/api/client/tasked";
import * as utils from "skCommon/utils/data";
import { Dataset } from "skCommon/ragnar/provider";
import { ProviderCode } from "skCommon/ragnar/providerDefs";
import { SkCursorPagination } from "skCommon/api/client/pagination";
import { CONTENT_TYPES, RESPONSE_FORMAT } from "skCommon/core/http";
import { ApiClient } from "skCommon/api/client/apiClient";
import { SPACEKNOW_OAUTH } from "skCommon/auth/authenticator";
import { RagnarScene } from "skCommon/ragnar/ragnarScene";

// Constant taken from SpaceKnow/backend/python/sk/backend/ragnar/size_utils.py
// Subtract 5% from area to evade some issues in algorithm services that may
// fail because of rounding.
export const MAX_AREA_PX = 0.95 * 3e6; // 3 MPX
export const DEFAULT_MIN_INTERSECTION = 0.4;

export class RagnarClient extends ApiClient {
    public readonly api = "ragnar-api";
    public readonly authType = SPACEKNOW_OAUTH;
    public contentType = CONTENT_TYPES.JSON;

    public getImage = this.makeTaskedEndpoint<GetImagePayload, GetImageResponse>({
        endpoint: "get-image",
    });

    public order = this.makeTaskedEndpoint<OrderPayload, OrderResponse>({
        endpoint: "order",
    });

    public search(query: RagnarSearchQuery): TaskedResult<RagnarScene[]> {
        const result = this.taskedCall<RagnarScene>({
            method: "POST",
            endpoint: "search",
            body: {
                extent: query.extent,
                provider: query.dataset.providerCode,
                dataset: query.dataset.code,
                startDatetime: query.fromDate && utils.formatDate(query.fromDate),
                endDatetime: query.toDate && utils.formatDate(query.toDate),
                minIntersection: query.minIntersection ?? DEFAULT_MIN_INTERSECTION,
                onlyIngested: query.ingestedOnly,
            },
            paginator: new SkCursorPagination(),
        });

        return result;
    }

    public sceneInfo(sceneId: string): Promise<RagnarScene> {
        return this.call<RagnarScene>({
            method: "POST",
            endpoint: "scene-info",
            body: {
                sceneId,
            },
        }).promise;
    }

    public thumbnail(sceneId: string): Promise<Blob> {
        return this.call<Blob>({
            method: "GET",
            endpoint: "thumbnail",
            params: {
                sceneId,
            },
            responseFormat: RESPONSE_FORMAT.BLOB,
        }).promise;
    }

    public ingestScene(
        provider: ProviderCode,
        file: File,
        type: IngestSceneFileType,
    ): Promise<IngestSceneResponse> {
        return this.call<IngestSceneResponse>({
            method: "POST",
            endpoint: "ingest-scene",
            params: {
                provider,
            },
            body: file,
            headers: new Headers({
                "Content-Type": type,
            }),
            maxRetriesOnServerError: 0,
            maxRetriesOnNetworkError: 0,
        }).promise;
    }
}

let client: RagnarClient;
let passiveClient: RagnarClient;

export function getRagnarClient(options?: TaskedClientConstructorOptions) {
    if (options && options.passive) {
        if (!passiveClient) {
            passiveClient = new RagnarClient(options);
        }

        return passiveClient;
    } else {
        if (!client) {
            client = new RagnarClient();
        }

        return client;
    }
}

///
///
/// Errors
///
///

export class RagnarClientError extends SkError {
    public dataToLog = {};

    constructor(msg: string) {
        super("RagnarClientError", msg);
    }
}

export interface GetImagePayload {
    sceneId: string;
    extent: GeoJSON.GeoJsonObject;
    exportRaster?: ExportRasterType;
}

export interface GetImageResponse {
    url: string;
    meta: RagnarScene;
    extent: GeoJSON.GeoJsonObject;
}

export interface SizeInfoResponse {
    max_dim: number;
    max_area: number;
    max_ratio: number;
}

export interface MaxSizeInfo {
    dimension: number;
    area: number;
    ratio: number;
}

export interface RagnarSearchQuery {
    extent: GeoJSON.GeoJsonObject;
    dataset: Dataset<ProviderCode>;
    fromDate?: Date;
    toDate?: Date;
    minIntersection?: number;
    ingestedOnly?: boolean;
}

export interface OrderResponse {
    status: OrderState;
}

interface IngestSceneResponse {
    ingestedScenes: IngestedScene[];
}

export interface IngestedScene {
    sceneId: string;
    duplicate: boolean;
}

export enum OrderState {
    Submitted = "SUBMITTED",
    Ordering = "ORDERING",
    Placed = "PLACED",
    Delivered = "DELIVERED",
    Failed = "FAILED",
}

export enum IngestSceneFileType {
    Zip = "application/zip",
    Tiff = "image/tiff",
}

export enum ExportRasterType {
    Tiff = "tiff",
    Jpeg2000 = "jp2",
}

interface OrderPayload {
    previewSceneId: string;
    extent: GeoJSON.GeoJsonObject;
}
