import { TaskedResult, TaskedClientConstructorOptions } from "skCommon/api/client/tasked";
import { Scene } from "skCommon/ragnar/scene";
import { CONTENT_TYPES } from "skCommon/core/http";
import { SkError } from "skCommon/core/error";
import { ApiClient } from "skCommon/api/client/apiClient";
import { Layer } from "skCommon/kraken/types";
import { SPACEKNOW_OAUTH } from "skCommon/auth/authenticator";
import { Tile } from "skCommon/utils/projection";
import { MapType } from "skCommon/kraken/algorithm/mapType";
import { NonEmptyArray } from "skCommon/utils/types";

// Unitless number that decides max area for datasets
// maxArea = (KRAKEN_MAX^2 * resolution^2)
export const KRAKEN_MAX = 70000;
// Let user request only part of the area so the polygon with area below this
// constant is always valid no matter where it's positioned relative to tiles.
// Also apply the power of 2 so it's easier to use.
export const KRAKEN_MAX_REQUESTABLE = KRAKEN_MAX ** 2;
export const KRAKEN_ENABLED = true;

export class KrakenClient extends ApiClient {
    public readonly api = "kraken-api";
    public readonly authType = SPACEKNOW_OAUTH;
    public contentType = CONTENT_TYPES.JSON;

    public dryRun = this.makeTaskedEndpoint<KrakenDryRunPayload, KrakenDryRunResult>({
        endpoint: "dry-run",
    });

    public pairwise = this.makeTaskedEndpoint<PairwisePayload, PairwiseResponse>({
        endpoint: "pairwise",
    });

    public nwise = this.makeTaskedEndpoint<NwisePayload, NwiseResponse>({
        endpoint: "nwise",
    });

    public protect = this.makeSimpleEndpoint<ProtectData, ProtectData>({
        endpoint: "protect",
    });

    public release(
        mapType: MapType,
        scenes: NonEmptyArray<Scene> | NonEmptyArray<string>,
        extent: GeoJSON.GeoJsonObject,
        pipelineId?: string,
    ): TaskedResult<KrakenReleaseResponse> {
        const sceneIds = typeof scenes[0] === "string"
            ? scenes
            : (scenes as NonEmptyArray<Scene>).map(scene => scene.id);
        const clippedImagery = typeof scenes[0] !== "string"
            && scenes[0].clippedImagery;

        return this.taskedCall<KrakenReleaseResponse>({
            endpoint: "/release",
            body: <KrakenReleaseGeojsonRequestPayload>{
                sceneIds,
                extent,
                clippedImagery,
                mapType,
            },
            pipelineId,
        });
    }

    public gridJson<T = GridResponse>(
        tile: Tile,
        mapId: string,
        gridFile: GridFile,
        geometryId?: string,
        userThreshold?: number,
    ): Promise<T> {
        return this.call<T>({
            endpoint: "grid-filename",
            method: "GET",
            params: {
                mapId,
                geometryId: geometryId || "-",
                z: tile[0],
                x: tile[1],
                y: tile[2],
                filename: gridFile,
                user_threshold: userThreshold,
            },
            skipInfoLogs: true,
            skipAuthentication: true,
        }).promise;
    }
}

let client: KrakenClient;
let passiveClient: KrakenClient;

export function getKrakenClient(options?: TaskedClientConstructorOptions) {
    if (options && options.passive) {
        if (!passiveClient) {
            passiveClient = new KrakenClient(options);
        }

        return passiveClient;
    } else {
        if (!client) {
            client = new KrakenClient;
        }

        return client;
    }
}

export function setKrakenClient(newClient: KrakenClient) {
    client = newClient;
}

///
///
/// Errors
///
///

export class KrakenError extends SkError {
    private sceneId: string;

    get dataToLog() {
        return {
            sceneId: this.sceneId,
        };
    }

    constructor(err: string, scene?: Scene) {
        super("KrakenError", err);

        if (scene) {
            this.sceneId = scene.id;
        }
    }
}

///
///
/// band.json configuration
///
///

export const enum BandJsonType {
    Heatmap = "heatmap",
    AvgRade9 = "avg_rade9",
    Coherence = "coherence",
    Displacement = "vertical-displacement",
    CumulativeDisplacement = "cumulative-displacement",
    Elevation = "elevation",
}

export const enum HeatmapKeys {
    AnalyzedArea = "analyzedAreaM2",
    MeanHeat = "meanHeat",
    MeanAbsChange = "meanAbsChange",
}
export const enum AvgRade9Keys {
    AnalyzedArea = "analyzedAreaM2",
    MeanRadiance = "meanRadiance",
}
export const enum SlcStatKeys {
    AnalyzedArea = "analyzedAreaM2",
    Mean = "mean",
}

export interface BandsJsonMap {
    [BandJsonType.Heatmap]: HeatmapKeys;
    [BandJsonType.AvgRade9]: AvgRade9Keys;
    [BandJsonType.Coherence]: SlcStatKeys;
    [BandJsonType.Displacement]: SlcStatKeys;
    [BandJsonType.CumulativeDisplacement]: SlcStatKeys;
    [BandJsonType.Elevation]: SlcStatKeys;
}

export type GridCollection = GridResponse;

export type GridResponse = GridGeoJsonResponse | AreaJsonResponse | BandsJsonResponse;

export type GridGeoJsonResponse = GeoJSON.FeatureCollection<
    GeoJSON.Polygon,
    GeoJsonDetectionProps
>;

export type GeoJsonDetection<T = {}> = GeoJSON.Feature<
    GeoJSON.Polygon,
    (GeoJsonDetectionProps | AttentionRegionsProps | DetectionRegionProperties) & T
>;

export interface AttentionRegionsProps {
    area: number;
    class: Layer.Heatmap;
    eccentricity: number;
    mean: number;
    solidity: number;
}

export interface GeoJsonDetectionProps {
    area: number;
    class: Layer;
    count: number;
    orientation: number;
}

export interface DetectionRegionProperties {
    class: Layer;
}

export interface AreaJsonResponse {
    analysisMetadata: {
        algoVersion: string;
        sScore: number;
    };
    algoVersion: string;
    areasM2: AreasM2;
}

export type AreasM2 = Record<string, Record<string, number> | number>;

export interface BandsJsonResponse {
    bandStatistics: {
        [k in keyof BandsJsonMap]: {
            [l in BandsJsonMap[k]]: number;
        };
    };
    /**
     * This property is available if `user_threshold` was given to the grid
     * endpoint.
     */
    thresholdedAreas?: ThresholdedAreas;
}

export interface ThresholdedAreas {
    areasM2: Record<string, Record<string, number> | number>;
}

export interface KrakenDryRunPayload {
    dryRuns: DryRunGroup[];
    extent: GeoJSON.GeoJSON;
    allocate?: boolean;
}

export interface DryRunGroup {
    scenes: string[][];
    mapTypes: string[];
}

export interface KrakenDryRunResult {
    ingestedKm2: number;
    krakenKm2: number;
    analyzedKm2: number;
    allocatedKm2: number;
    allocatedCredits: number;
}

interface KrakenReleaseGeojsonRequestPayload {
    sceneIds: string[];
    mapType: MapType;
    extent: GeoJSON.GeoJsonObject;
    clippedImagery?: boolean;
}

export interface KrakenReleaseResponse {
    mapId: string;
    maxZoom: number;
    tiles: Tile[];
}

export enum CountFeaturesType {
    /**
     * GeoJSON is requested from kraken and total number of features of given
     * class is stored.
     */
    Detections,
    /**
     * JSON is requested from kraken and sum of contained values (under given
     * key) is stored
     */
    Area,
    /**
     * JSON is requested and sum of values is stored only when sum of all keys
     * is not less than polygon's area. Useful for algorithms such as wrunc,
     * where we do not want to display misleading plot values (when part of
     * image is invalid). Requested area value is also not computed using our
     * actual polygon's area but it's a sum across all classes instead.
     */
    FullArea,
    /**
     * Data is in heatmap format.
     */
    Heatmap,
    None,
}

export interface MapFilePayload {
    mapId: string;
    mapType: string;
    tiles: {
        x: number;
        y: number;
        zoom: number;
    }[];
    exp: number;
}

export interface PairwisePair {
    oldSceneId: string;
    newSceneId: string;
}

export enum PairwisePeriodicity {
    None = "none",
    Daily = "daily",
    Weekly = "weekly",
    AmWeekly = "amweekly",
    Monthly = "monthly",
    Yearly = "yearly",
}

export enum PairwisePairingType {
    Continuous = "continuous",
    First = "first",
    YearOverYear = "yoy",
}

export interface PairwisePayload {
    sceneIds: string[];
    extent: GeoJSON.GeoJsonObject;
    pairingType: PairwisePairingType;
    periodicity?: PairwisePeriodicity;
    minDayDelta?: number;
    maxDayDelta?: number;
}

export interface NwisePayload {
    sceneIds: string[];
    extent: GeoJSON.GeoJsonObject;
    groupingType: NwiseGroupingType;
    /**
     * Spacing between windows for grouping
     */
    windowStepDays?: number;
    /**
     * Date from which to start calculating group windows
     */
    windowStart?: string;
    /**
     * Length of the window for one group to fit in
     */
    windowSizeDays?: number;
    /**
     * Start date of window of interest.
     * Required for the cumulative-yoy grouping type.
     */
    startDate?: string;
    /**
     * End date of window of interest.
     * Required for the cumulative-yoy grouping type.
     */
    endDate?: string;
    /**
     * Minimum intersection area for the scene pair to be considered for YoY
     * pairing.
     * Required for the cumulative-yoy grouping type.
     */
    yoyPairMinIntersectionKm2?: number;
}

interface PairwiseResponse {
    pairwise: PairwisePair[];
}

interface NwiseResponse {
    nwise: string[][];
}

export interface ProtectData {
    token: string;
}

export enum GridFile {
    Detections = "detections.geojson",
    AttentionRegions = "attention_regions.geojson",
    Metadata = "metadata.json",
    DetectionRegions = "detection_regions.geojson",
}

export enum NwiseGroupingType {
    Windowed = "windowed",
    Cumulated = "cumulated",
    CumulatedYoy = "cumulated-yoy",
}
