import { CONTENT_TYPES, RESPONSE_FORMAT } from "skCommon/core/http";
import { SkCursorPagination } from "skCommon/api/client/pagination";
import { parseDate, formatShortDate, formatDate } from "skCommon/utils/data";
import { callFetch as fetch } from "skCommon/core/fetch";
import { jsonStringifyWithFloats } from "skCommon/utils/float";
import { SkError } from "skCommon/core/error";
import { SPACEKNOW_OAUTH } from "skCommon/auth/authenticator";
import { ApiClient } from "skCommon/api/client/apiClient";
import { csvToJson } from "skCommon/utils/csv";
import { assert } from "skCommon/utils/assert";

export class DatacubeClient extends ApiClient {
    public readonly api = "datacube-api";
    public readonly authType = SPACEKNOW_OAUTH;
    public contentType = CONTENT_TYPES.JSON;

    public getProducts = this.makeSimpleEndpoint<GetProductsPayload | void, RawProduct[]>({
        endpoint: "get-products",
    });

    public updateProduct = this.makeSimpleEndpoint<EditableRawProduct, {}>({
        endpoint: "update-product",
    });

    public getProduct = this.makeSimpleEndpoint<GetProductPayload, string>({
        endpoint: "get-product",
        responseFormat: RESPONSE_FORMAT.PLAIN,
    });

    public listProductPackages = this.makeSimpleEndpoint<{ userId: string }, ProductPackage>({
        endpoint: "list-product-packages",
        pagination: new SkCursorPagination(),
    });

    public createProductPackage = this.makeSimpleEndpoint<
        CreateProductPackagesPayload,
        CreatedPackage
    >({
        endpoint: "create-product-packages",
    });

    public deleteProductPackage = this.makeSimpleEndpoint<{ packageId: string }, {}>({
        endpoint: "delete-product-packages",
    });

    public getProductSalesInfo = this.makeSimpleEndpoint<GetProductPayload, string>({
        endpoint: "sales-info",
        responseFormat: RESPONSE_FORMAT.BLOB,
    });

    public listPackages(userId: string): Promise<DatacubePackage[]> {
        return this.call<DatacubePackage>({
            endpoint: "list-packages",
            paginator: new SkCursorPagination(),
            body: { userId },
        }).promise;
    }

    public createPackage(
        payload: DatacubePackagePayload,
    ): Promise<{ packageId: string }> {
        return this.call<{ packageId: string }>({
            endpoint: "create-package",
            body: payload,
        }).promise;
    }

    public deletePackage(packageId: string): Promise<{}> {
        return this.call<{}>({
            endpoint: "delete-package",
            body: { packageId },
        }).promise;
    }

    public get(query: DatacubeGetQuery): Promise<GetDatapointsResponse> {
        return this.call<GetDatapointsResponse>({
            endpoint: "get-datapoints",
            body: {
                filters: query.filters,
                aggregate: query.aggregate,
                ignoreCache: query.ignoreCache,
            },
        }).promise;
    }

    public getAsync(query: DatacubeAsyncGetQuery): Promise<GetDatapointsResponse> {
        return this.taskedCall<GetDatapointsResponse>({
            endpoint: "get-datapoints",
            body: query,
        }).promise;
    }

    public async getAndParseProduct(payload: GetProductPayload): Promise<ProductData[]> {
        const csv = await this.getProduct(payload);

        return this.parseProductCsv(csv);
    }

    public parseProductCsv(csv: string): ProductData[] {
        const rows = csvToJson<ProductCsvRow>(csv);

        return rows.map(row => {
            const valueDate = safeParseDatacubeDate(row.value_dt);
            const deliveryDate = safeParseDatacubeDate(row.delivery_dt);
            const aoiId = row.aoi_id;

            delete row.delivery_dt;
            delete row.value_dt;
            delete row.aoi_id;

            const values: Record<string, number> = Object.fromEntries(
                Object.entries(row)
                    .filter(([k]) => k !== "delivery_dt" && k !== "value_dt")
                    .map(([k, v]) => [k as string, v ? parseFloat(v) : null]),
            );

            return {
                valueDate,
                deliveryDate,
                aoiId,
                values,
            };
        });
    }

    public getAois(): Promise<AoiDef[]> {
        return this.call<AoiDef[]>({
            endpoint: "get-aois",
        }).promise;
    }

    public async getCatalogue(): Promise<CatalogueItem[]> {
        const map = await this.call<GetCatalogueResponse>({
            endpoint: "get-catalogue",
        }).promise;

        return Object.keys(map)
            .map(key => map[key].map(item => ({
                ...item,
                algorithm: key,
            })))
            .reduce((acc, arr) => acc.concat(arr), [])
            .map(item => ({
                version: item.version,
                aoi: item.aoi_label,
                aoiName: item.aoi_name,
                source: item.source,
                project: item.project,
                lastUpdate: parseDate(item.last_update),
                dateRanges: item.date_ranges.map(range => ({
                    from: parseDate(range.from),
                    to: parseDate(range.to),
                })),
                algorithm: item.algorithm,
                endOfLife: item.end_of_life && parseDate(item.end_of_life),
            }));
    }

    public async updateCatalogue(item: CatalogueBaseItem): Promise<{}> {
        const apiItem: ApiUpdateCatalogueItem = {
            aoiLabel: item.aoi,
            aoiName: item.aoiName,
            algorithm: item.algorithm,
            project: item.project,
            source: item.source,
            version: item.version,
            dateRanges: item.dateRanges.map(obj => ({
                from: formatShortDate(obj.from),
                to: formatShortDate(obj.to),
            })),
        };

        return this.call<{}>({
            endpoint: "update-catalogue",
            body: apiItem,
        }).promise;
    }

    /**
     * Create given datapoints in the datacube.
     *
     * The endpoint is very sensitive to the number format and the regular
     * JSON.stringify never stringifies 0 as 0.0, which is required by the
     * endpoint. So the body needs to be manually stringified.
     */
    public createDatapoints(
        datapoints: NewDatacubeDataPoint[],
        dropDuplicates: boolean = false,
    ): Promise<{}> {
        return this.call<{}>({
            endpoint: "create-datapoints",
            body: jsonStringifyWithFloats({
                datapoints,
                dropDuplicates,
            }),
        }).promise;
    }

    /**
     * Load and open datacube csv from storage. Only supports regular (not
     * pivoted) datacube CSV.
     */
    public async openDatacubeCsv(csvUrl: string): Promise<DatacubeDataPoint[]> {
        const text = await this.fetchDatacubeCsv(csvUrl);

        return this.openDatacubeCsvString(text);
    }

    /**
     * Fetch datacube csv from storage and return as string.
     */
    public async fetchDatacubeCsv(csvUrl: string): Promise<string> {
        const res = await fetch(csvUrl);
        const text = await res.text();

        if (res.status >= 300) {
            throw new DatacubeError("Cannot open CSV.", text);
        }

        return text;
    }

    public openDatacubeCsvString(data: string): DatacubeDataPoint[] {
        const lines = data.split("\n");
        const cols = lines[0].split(",");
        const colMap: Map<keyof RawDatacubeDataPoint, number> = new Map(
            cols.map((str, i) => [str, i] as [keyof RawDatacubeDataPoint, number]),
        );

        // Datacube values do not contain commas, so we can safely just
        // split by comma
        return lines.slice(1)
            .filter(row => row.length > 0)
            .map(row => row.split(","))
            .map(cells => {
                const out: Partial<DatacubeDataPoint> = {};

                for (const key of DATACUBE_DATA_POINT_FIELDS) {
                    if (colMap.has(key)) {
                        const colValue = cells[colMap.get(key)];
                        out[key] = DATACUBE_DATA_POINT_PARSERS[key](colValue);
                    }
                }

                return out as DatacubeDataPoint;
            });
    }

    public async openPivotedDatacubeCsv(csvUrl: string): Promise<DatacubePivotTable> {
        const res = await fetch(csvUrl);
        const data = await res.text();

        const tableData = data.split("\n")
            .slice(0, -1)
            .map(row => row.split(","));

        const columns = tableData[0].slice(1);
        const rows = tableData.slice(1).map(row => new Date(row[0]));
        const values = tableData.slice(1).map(
            row => row.slice(1).map(v => v ? parseFloat(v) : null),
        );

        return {
            columns,
            rows,
            values,
        };
    }

    public async listSubscriptions(): Promise<DatacubeSubscriptions> {
        return this.call<DatacubeSubscriptions>({
            endpoint: "list-subscriptions",
        }).promise;
    }

    public async updateSubscription(sub: RawDatacubeSubscription): Promise<void> {
        await this.call<{}>({
            endpoint: "update-subscription",
            body: sub,
        }).promise;
    }
}

let datacubeClient: DatacubeClient;

export function getDatacubeClient() {
    if (!datacubeClient) {
        datacubeClient = new DatacubeClient();
    }

    return datacubeClient;
}

export function compareDataPoints(a: DatacubeDataPoint, b: DatacubeDataPoint) {
    if (a.startDatetime > b.startDatetime) {
        return 1;
    } else if (a.startDatetime < b.startDatetime) {
        return -1;
    } else {
        if (a.aoiId > b.aoiId) {
            return 1;
        } else if (a.aoiId < b.aoiId) {
            return -1;
        } else {
            return 0;
        }
    }
}

export function safeParseDatacubeDate(input: string): Date {
    const d = parseDatacubeDate(input);

    if (Number.isNaN(d.getTime())) {
        throw new Error(`Could not parse Datacube date "${input}"`);
    } else {
        return d;
    }
}

export function optionalParseDatacubeDate(input: string): Date | undefined {
    const d = parseDatacubeDate(input);

    if (Number.isNaN(d.getTime())) {
        return undefined;
    } else {
        return d;
    }
}

/**
 * Helper to create "from to" object used by some filters, since it is a
 * "at least one property defined" object, which is hard to write nicely in
 * TS strict mode. While also taking care of converting the dates into correct
 * format.
 */
export function makeFromToParam(from?: Date, to?: Date): FromToParam {
    assert(from || to, `Either "from" or "to" date needs to be provided`);

    const fromObj = from ? { from: formatDate(from) } : {};
    const toObj = to ? { to: formatDate(to) } : {};

    return { ...fromObj, ...toObj } as FromToParam;
}

/**
 * Parses date formats that can be found in datacube CSV. Depending on
 * aggregation, different formats are used.
 */
export function parseDatacubeDate(input: string): Date {
    /**
     * Weekly aggregation: 2016-W03
     */
    const weekExpression = /^([0-9]{4})-W([0-9]{2})$/;
    /**
     * Daily aggregation: 2016-02-23
     */
    const dayExpression = /^([0-9]{4})-([0-9]{2})-([0-9]{2})$/;

    if (weekExpression.test(input)) {
        const match = weekExpression.exec(input);
        const year = parseFloat(match[1]);
        const week = parseFloat(match[2]);

        const firstDay = new Date(Date.UTC(year, 0, 1)).getUTCDay();
        const date = Math.max(1, 1 + (8 - firstDay) % 7 + (week - 1) * 7);

        return new Date(Date.UTC(year, 0, date));
    } else if (dayExpression.test(input)) {
        const match = dayExpression.exec(input);

        return new Date(Date.UTC(
            parseInt(match[1]),
            parseInt(match[2]) - 1,
            parseInt(match[3]),
        ));
    } else {
        // No aggregation: 2016-01-23 16:00:23 UTC
        return parseDate(input);
    }
}

export class DatacubeError extends SkError {
    public dataToLog = {
        response: null,
    };

    constructor(msg: string, response: string) {
        super("DatacubeError", msg);
        this.dataToLog.response = response;
    }
}

export const DATACUBE_DATA_POINT_FIELDS: (keyof RawDatacubeDataPoint)[] = [
    "version",
    "startDatetime",
    "endDatetime",
    "ingestDatetime",
    "firstSeen",
    "algorithm",
    "project",
    "aoi",
    "aoiId",
    "source",
    "cloudCover",
    "intersectionRatio",
    "value",
    "seasonalDecomposition",
    "valueSeasonallyAdjusted",
    "trend",
    "gap",
    "valueScaled",
];

type DataPointParserMap = {
    [k in keyof RawDatacubeDataPoint]: ((v: string) => any);
};

export const DATACUBE_DATA_POINT_PARSERS: DataPointParserMap = {
    version: v => v,
    startDatetime: safeParseDatacubeDate,
    endDatetime: safeParseDatacubeDate,
    ingestDatetime: safeParseDatacubeDate,
    firstSeen: optionalParseDatacubeDate,
    algorithm: v => v,
    project: v => v,
    aoi: v => v,
    aoiId: v => v,
    source: v => v,
    cloudCover: parseFloat,
    intersectionRatio: parseFloat,
    value: parseFloat,
    seasonalDecomposition: parseFloat,
    valueSeasonallyAdjusted: parseFloat,
    gap: parseFloat,
    trend: parseFloat,
    valueScaled: parseFloat,
};

export interface RawDatacubeDataPoint extends SharedDataPointStruct {
    ingestDatetime: string;
}

export interface NewDatacubeDataPoint extends SharedDataPointStruct {
    aoi: GeoJSON.Point;
    rowId: string;
}

/**
 * Structure which is shared by both existing and new data points. Optional
 * properties are dictated by the new data point structure.
 */
interface SharedDataPointStruct {
    version: string;
    startDatetime: string;
    endDatetime: string;
    firstSeen: string;
    algorithm: string;
    project: string;
    aoi: GeoJSON.GeoJsonObject;
    aoiId: string;
    source: string;
    value: number;
    cloudCover?: string;
    intersectionRatio?: string;
    seasonalDecomposition?: string;
    valueSeasonallyAdjusted?: string;
    trend?: string;
    gap?: string;
    valueScaled?: string;
}

export interface DatacubeDataPoint {
    version: string;
    startDatetime: Date;
    endDatetime: Date;
    ingestDatetime: Date;
    firstSeen: Date;
    algorithm: string;
    project: string;
    aoiId: string;
    source: string;
    cloudCover: number;
    intersectionRatio: number;
    value: number;
    seasonalDecomposition?: number;
    valueSeasonallyAdjusted?: number;
    trend?: number;
    gap?: number;
    valueScaled?: number;
}

export interface DatacubePivotTable {
    columns: string[];
    rows: Date[];
    values: number[][];
}

export interface DatacubePackagePayload
    extends DatacubePackageSettable {
    userId: string;
}

export interface DatacubePackage extends DatacubePackageSettable {
    packageId: string;
}

export interface DatacubePackageSettable {
    description: string;
    filters: DatacubeFilter[];
}

export type DatacubeFilter = DatacubeTimeRangeFilter
    | DatacubeValueListFilter
    | DatacubeGeoIntersectsFilter
    | DatacubeValueRangeFilter;

export interface DatacubeTimeRangeFilter extends DatacubeFilterBase {
    type: DatacubeFilterType.TimeRange;
    params: FromToParam;
}

export type FromToParam = {
    from: string;
} | {
    to: string;
};

export interface DatacubeGeoIntersectsFilter extends DatacubeFilterBase {
    type: DatacubeFilterType.GeoIntersects;
    params: {
        geometryLabel: string;
    };
}

export interface DatacubeValueListFilter extends DatacubeFilterBase {
    type: DatacubeFilterType.ValueList;
    params: {
        values: string[];
    };
}

export interface DatacubeValueRangeFilter extends DatacubeFilterBase {
    type: DatacubeFilterType.ValueRange;
    params: {
        min?: number;
        max?: number;
    };
}

export interface DatacubeFilterBase {
    type: DatacubeFilterType;
    field: keyof RawDatacubeDataPoint;
}

export enum DatacubeFilterType {
    TimeRange = "time-range",
    ValueList = "value-list",
    GeoIntersects = "geo-intersects",
    ValueRange = "value-range",
}

/**
 * List of field supported by each filter type
 */
export const ALLOWED_FILTERS: Partial<{
    [k in keyof RawDatacubeDataPoint]: DatacubeFilterType[]
}> = {
    version: [DatacubeFilterType.ValueList],
    algorithm: [DatacubeFilterType.ValueList],
    project: [DatacubeFilterType.ValueList],
    aoiId: [DatacubeFilterType.ValueList],
    source: [DatacubeFilterType.ValueList],
    startDatetime: [DatacubeFilterType.TimeRange],
    endDatetime: [DatacubeFilterType.TimeRange],
    ingestDatetime: [DatacubeFilterType.TimeRange],
    aoi: [DatacubeFilterType.GeoIntersects],
};

export const FILTERS_TYPE_NAMES: { [ k in DatacubeFilterType ]: string } = {
    [DatacubeFilterType.TimeRange]: "Time range",
    [DatacubeFilterType.ValueList]: "List of allowed values",
    [DatacubeFilterType.GeoIntersects]: "Area of interest",
    [DatacubeFilterType.ValueRange]: "Value range",
};

export interface GetDatapointsResponse {
    csvLink: string;
    totalRows: number;
}

export interface AoiDef {
    label: string;
    name: string;
    /**
     * Currently only available when aois requested as admin!
     */
    geometryId?: string;
}

export interface CatalogueItem extends CatalogueBaseItem {
    lastUpdate: Date;
}

/**
 * Data structure returned by the API has some naming incosistencies so this
 * object should be used instead
 */
export interface CatalogueBaseItem {
    version: string;
    aoi: string;
    aoiName: string;
    source: string;
    project: string;
    dateRanges: {
        from: Date;
        to: Date;
    }[];
    algorithm: string;
    endOfLife?: Date;
}

interface GetCatalogueResponse {
    [k: string]: ApiGetCatalogueItem[];
}

export interface CatalogueRange {
    from: Date;
    to: Date;
}

export interface DatacubeGetQuery {
    filters: DatacubeFilter[];
    aggregate?: DatacubeAggregation;
    resample?: DatacubeResample;
    ignoreCache?: boolean;
    keepDuplicates?: boolean;
    keepAllVersions?: boolean;
}

export enum DatacubeResample {
    Weekly = "W",
    Monthly = "M",
    Yearly = "Y",
}

export interface DatacubeAsyncGetQuery extends DatacubeGetQuery {
    postprocess?: PostprocessType;
    columns?: (keyof RawDatacubeDataPoint)[];
}

interface ApiGetCatalogueItem {
    version: string;
    aoi_label: string;
    aoi_name: string;
    source: string;
    project: string;
    date_ranges: {
        from: string;
        to: string;
    }[];
    end_of_life?: string;
    last_update: string;
}

interface ApiUpdateCatalogueItem {
    version: string;
    aoiLabel: string;
    aoiName: string;
    source: string;
    project: string;
    algorithm: string;
    dateRanges: {
        from: string;
        to: string;
    }[];
    endOfLife?: string;
}

export enum PostprocessType {
    Pivot = "pivot",
    Columns = "columns",
    SeasonalDecompose = "seasonal_decompose",
    HpFilter = "sd_hpfilter",
}

export enum DatacubeAggregation {
    Daily = "daily",
    Weekly = "weekly",
    Monthly = "monthly",
    Yearly = "yearly",
}

export interface DatacubeSubscriptions {
    [name: string]: RawDatacubeSubscription;
}

export interface RawDatacubeSubscription {
    emails: string[];
    expirationTime: number;
    name: string;
    queries: {
        [queryName: string]: SubscriptionQuery;
    };
}

export interface BaseSubscriptionQuery {
    filters: DatacubeFilter[];
    aggregate?: DatacubeAggregation;
    postprocess?: PostprocessType;
    columns?: string[];
}

export interface SubscriptionQuery extends BaseSubscriptionQuery {
    ignoreCache: true;
}

export interface RawProduct {
    productId: string;
    requestDefinitions: RequestDefinition[];
    internalMetadata: Record<string, any>;
    active: boolean;
    downloadable: boolean;
    metadata?: Record<string, any>;
}

export type EditableRawProduct = Omit<RawProduct, "downloadable">;

export type RequestDefinition = [string, DatacubeGetQuery];

export interface GetProductPayload {
    productId: string;
    pitDt?: Date;
}

export interface ProductCsvRow {
    value_dt: string;
    delivery_dt: string;
    [k: string]: string;
}

export interface ProductData {
    valueDate: Date;
    deliveryDate: Date;
    aoiId?: string;
    values: Record<string, number>;
}

export interface GetProductsPayload {
    productId?: string;
}

export interface CreateProductPackagesPayload {
    userId: string;
    description: string;
    productIds: string[];
}

export interface ProductPackage {
    packageId: string;
    description: string;
    productIds: string[];
}

export interface CreatedPackage {
    packageId: string;
}
