import { Client } from "skCommon/api/client/simple";
import { SPACEKNOW_OAUTH } from "skCommon/auth/authenticator";

export class UserClient extends Client {
    public readonly api = "user-api";
    public readonly authType = SPACEKNOW_OAUTH;

    public readonly contentType = "application/json; charset=utf-8";

    public getManagementToken() {
        return this.call<ManagementTokenResponse>(
            {
                endpoint: "manage",
            },
        ).promise;
    }

    public info(): Promise<BackendUserInfo> {
        return this.call<BackendUserInfo>(
            {
                endpoint: "info",
            },
        ).promise;
    }

    public foreignInfo(token: string): Promise<BackendUserInfo> {
        return this.call<BackendUserInfo>(
            {
                endpoint: "info",
                skipAuthentication: true,
                headers: new Headers({
                    Authorization: `Bearer ${token}`,
                }),
            },
        ).promise;
    }
}

export const userClient = new UserClient();

interface ManagementTokenResponse {
    authorization: string;
}

export interface BackendUserInfo {
    accounting: string;
    email: string;
    id: string;
    permissions: string[];
    name?: string;
}
