import { SkError } from "skCommon/core/error";
import { urlUtil as url } from "skCommon/api/config/url";
import {
    ApiConfigPropertyByHost,
    DOMAIN_GROUPS,
    ApiConfigGroups,
} from "skCommon/api/apiConfig";

let defaultConfig: Config;

/**
 * Config related functionality
 *
 * @export
 * @class Config
 */
export class Config {
    private hosts = new Array<string>();

    public get url() {
        return url;
    }

    private currentHost = typeof self !== "undefined"
        ? self.location.hostname
        // @ts-ignore
        : process.env.GCLOUD_PROJECT;

    constructor(hostOverride?: string) {
        if (hostOverride) {
            this.currentHost = hostOverride;
        }
        this.setupHosts(DOMAIN_GROUPS);
    }

    /**
     * Resolve a property depending on current host.
     * @throws {ConfigResolvePropertyByHostError}
     */
    public getByHost<T = string>(property: ApiConfigPropertyByHost<T>): T {
        const propertyHosts = new Set(Object.keys(property));
        const matchedHost = this.hosts.find((host) => {
            return propertyHosts.has(host);
        });

        if (!matchedHost) {
            let err: ConfigResolvePropertyByHostError;

            err = new ConfigResolvePropertyByHostError(
                ConfigResolvePropertyByHostError.ERR_UNDEFINED_FOR_HOST,
                this.hosts,
            );

            throw err;
        }

        return property[matchedHost];
    }

    private async setupHosts(groups: ApiConfigGroups) {
        this.hosts.push(...groups[this.currentHost]);
    }
}

export function getDefaultConfig(hostOverride?: string): Config {
    if (!defaultConfig) {
        defaultConfig = new Config(hostOverride);
    }
    return defaultConfig;
}

///
///
/// Errors
///
///

export abstract class ConfigError extends SkError { }

export class ConfigResolvePropertyByHostError extends ConfigError {

    public static readonly ERR_UNDEFINED_FOR_HOST = "ERR_UNDEFINED_FOR_HOST";

    public static readonly MESSAGES = new Map(<[string, string][]>[
        [
            ConfigResolvePropertyByHostError.ERR_UNDEFINED_FOR_HOST,
            "Property has no value for any of current hosts",
        ],
    ]);

    get dataToLog() {
        return {
            hosts: this.hosts,
        };
    }

    constructor(code: string, public hosts?: string[]) {
        super("ConfigResolvePropertyByHostError", code);
    }
}

