import { Client } from "skCommon/api/client/simple";
import { getDefaultConfig } from "skCommon/api/config";
import { registerAuthClient } from "skCommon/auth/client";
import { Auth0Client } from "skCommon/auth0/client";
import { getUser, User } from "skCommon/core/user";
import { DatacubeClient } from "skCommon/datacube/client";
import { KrakenClient } from "skCommon/kraken/client";
import { RagnarClient } from "skCommon/ragnar/client";
import { InsightsApiClient } from "skCommon/insightsApi/client";

// Hacky way to use production for any URL since frontend apps all decide
// environment to use using current domain
getDefaultConfig("analytics.spaceknow.com");

registerAuthClient(new Auth0Client());

window.$skUser = getUser();
window.$skApi = {
    ragnar: new RagnarClient(),
    datacube: new DatacubeClient(),
    kraken: new KrakenClient(),
    insightsApi: new InsightsApiClient(),
};

declare global {
    interface Window {
        $skUser: User;
        $skApi: Record<string, Client>;
    }
}
