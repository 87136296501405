import { TaskedClient, TaskedResult, TaskedClientAction } from "skCommon/api/client/tasked";
import { TaskResponse } from "skCommon/tasking/pipeline";
import { Method, ResponseFormat } from "skCommon/core/http";
import { Pagination } from "skCommon/api/client/pagination";
import { pickIfExists } from "skCommon/utils/object";
import { PaginatedOptions } from "skCommon/api/client/simple";

export abstract class ApiClient extends TaskedClient {
    protected makeTaskedEndpoint<P, R>(opts: EndpointOptions): TaskedEndpoint<P, R> {
        return createEndpoint<P, R>(this, opts);
    }

    // tslint:disable: max-line-length
    protected makeSimpleEndpoint<R>(opts: BasicSimpleEndpointOptions): PayloadlessSimpleEndpoint<R>;
    protected makeSimpleEndpoint<P, R>(opts: BasicSimpleEndpointOptions): SimpleEndpoint<P, R>;
    protected makeSimpleEndpoint<P, R>(opts: PaginatedSimpleEndpointOptions): SimpleEndpoint<P, R, true>;
    protected makeSimpleEndpoint<P, R>(opts: SimpleEndpointOptions): PayloadlessSimpleEndpoint<R> | SimpleEndpoint<P, R, boolean> {
    // tslint:enable: max-line-length
        return function (payload?: P) {
            let payloadOptions: Partial<PaginatedOptions> = {};
            const method = opts.method || Method.Post;
            const bodyfallback = method === Method.Get ? void 0 : {};

            if (opts.useParams) {
                payloadOptions = {
                    body: bodyfallback,
                    params: payload,
                };
            } else {
                payloadOptions = {
                    body: payload || bodyfallback,
                };
            }

            return (this as TaskedClient).call<R>({
                method,
                endpoint: opts.endpoint,
                ...pickIfExists(opts as PaginatedEndpointOptions, "pagination", "paginator"),
                ...pickIfExists(opts, "responseFormat"),
                ...payloadOptions,
            }).promise;
        }.bind(this);
    }
}

function createEndpoint<P, R>(
    scope: TaskedClient,
    opts: EndpointOptions,
): TaskedEndpoint<P, R> {
    const mainCall = function (payload: P, pipelineId: string) {
        return (this as TaskedClient).taskedCall<R>({
            method: Method.Post,
            endpoint: opts.endpoint,
            body: payload,
            pipelineId,
        });
    }.bind(scope);

    const helpers: TaskedEndpointHelpers<P, R> = {
        initiate: function (payload: P) {
            return (this as TaskedClient).call<R>({
                method: Method.Post,
                endpoint: opts.endpoint,
                body: payload,
                urlSuffix: TaskedClientAction.Initiate,
            });
        }.bind(scope),
        retrieve: function (pipelineId: string) {
            return (this as TaskedClient).call<R>({
                method: Method.Post,
                endpoint: opts.endpoint,
                body: { pipelineId },
                urlSuffix: TaskedClientAction.Retrieve,
            });
        }.bind(scope),
        resume: function (pipelineId: string) {
            return mainCall({}, pipelineId);
        }.bind(scope),
        retried: function (payload: P, pipelineId: string) {
            return (this as TaskedClient).taskedCall<R>({
                method: "POST",
                endpoint: opts.endpoint,
                body: payload,
                pipelineId,
                pipelineRetries: 2,
            });
        }.bind(scope),
    };

    for (const [k, fn] of Object.entries(helpers)) {
        mainCall[k] = fn;
    }

    return mainCall;
}

export type SimpleEndpointOptions = BasicSimpleEndpointOptions | PaginatedSimpleEndpointOptions;

export type EndpointOptions = BasicEndpointOptions | PaginatedEndpointOptions;

export type BasicSimpleEndpointOptions = BasicEndpointOptions & SimpleAdditionalOptions;

export interface BasicEndpointOptions {
    endpoint: string;
    responseFormat?: ResponseFormat;
}

export type PaginatedSimpleEndpointOptions = PaginatedEndpointOptions & SimpleAdditionalOptions;

export interface PaginatedEndpointOptions extends BasicEndpointOptions {
    pagination?: Pagination;
}

export type SimpleEndpoint<P, R, Paginated extends boolean = false> =
    (payload: P) => Promise<Paginated extends true ? R[] : R>;
export type PayloadlessSimpleEndpoint<R> = () => Promise<R>;

export interface TaskedEndpoint<PayloadType, ResponseType>
    extends TaskedEndpointHelpers<PayloadType, ResponseType> {

    (payload: PayloadType, pipelineId?: string): TaskedResult<ResponseType>;
}

interface TaskedEndpointHelpers<PayloadType, ResponseType> {
    resume: (pipelineId: string) => TaskedResult<ResponseType>;
    initiate: (payload: PayloadType) => Promise<TaskResponse>;
    retrieve: (pipelineId: string) => Promise<ResponseType>;
    retried: (payload: PayloadType, pipelineId?: string) => TaskedResult<ResponseType>;
}

interface SimpleAdditionalOptions {
    method?: Method;
    useParams?: boolean;
}
