import { Subject } from "rxjs";

import { getLogger, Logger } from "skCommon/utils/logger";
import { generateUniqueId } from "skCommon/utils/uniqueId";
import { getDefaultConfig } from "skCommon/api/config";
import * as http from "skCommon/core/http";
import { SkError, SkErrorWrap } from "skCommon/core/error";
import {
    getDefaultAuthentication,
    AuthDataPayload,
} from "skCommon/core/authentication";
import * as paging from "skCommon/api/client/pagination";
import { API_CONFIG, ApiObject } from "skCommon/api/apiConfig";

export abstract class Client extends http.Http {
    public static readonly DEFAULT_METHOD = http.Method.Post;

    public abstract readonly api: keyof typeof API_CONFIG;
    public abstract readonly authType: string;

    public readonly contentType: string = undefined;

    protected readonly log: Logger = getLogger();

    protected _defaultOptions: ClientOptions = {
        method: Client.DEFAULT_METHOD,
        endpoint: null,
        responseFormat: http.RESPONSE_FORMAT.JSON,
        skipTracing: true,
    };

    protected get apiObject(): ApiObject {
        return API_CONFIG[this.api];
    }

    public getDefaultOptions(): ClientOptions {
        return this._defaultOptions;
    }

    public call<T>(
        options: PaginatedOptions,
    ): Result<T[]>;
    public call<T>(
        options: ClientOptions,
    ): Result<T>;
    public call<T>(
        options: ClientOptions | PaginatedOptions,
    ): Result<T[]> | Result<T> {

        const result = "paginator" in options
            ? new PaginatedResult<T>(this.apiObject, options, this.log)
            : new Result<T>(this.apiObject, options, this.log);

        if (!options.trace) {
            options.trace = generateUniqueId(12);
        }

        if (!options.skipInfoLogs) {
            this.log.debug("API call", {
                endpoint: options.endpoint,
                trace: options.trace,
                paginated: result instanceof PaginatedResult,
            });
        }

        const promise = this
            .urlForCall(options)
            .then<T | T[]>((url) => {
                if (result instanceof PaginatedResult) {
                    return this.makePaginatedCall(
                        result,
                        url,
                        result.requestOptions,
                    );
                } else {
                    return this.makeCall(
                        result,
                        url,
                        options,
                    );
                }
            })
            .catch((e: Error) => this.throwClientError(e, options));
        // cast because weird typing
        result.promise = <any>promise;
        return result;
    }

    public async urlForCall(options: ClientOptions) {
        const urlUtil = getDefaultConfig().url;

        return urlUtil.get(this.apiObject, options.endpoint, options.params, options.urlSuffix);
    }


    protected async setAuthData(
        url: string,
        options: ClientOptions,
    ): Promise<AuthDataPayload> {
        const payload = {
            options,
            url,
            api: this.api,
        };

        if (
            typeof this.authType === "string"
            && !options.skipAuthentication
        ) {
            await this.tryToRenewAuthData();

            return getDefaultAuthentication()
                .setRequestAuthData(this.authType, payload);
        }

        return payload;
    }

    /**
     * Validates current authentication data and if it's not valid it tries to
     * renew it. Return boolean indicating whether the data were renwed
     */
    protected async tryToRenewAuthData(): Promise<boolean> {
        const authentication = getDefaultAuthentication();
        const authValid = await authentication.validate(this.authType);

        // Authentication data is not valid so let's try to renew that data
        if (!authValid) {
            let renewalOk = false;

            try {
                renewalOk = await authentication.renew(this.authType);
            } catch (e) {
                getLogger().error(e);
            }

            // renewal of authentication data was successfull so there's
            // nothing more to do here
            if (renewalOk) {
                return true;
            }

            // renewal failed so let's revoke the authentication
            // explicitly since there might be logic connected to that
            authentication.revoke(this.authType);
        }

        return false;
    }

    protected async makeCall<T>(
        _result: Result<T>,
        url: string,
        options: ClientOptions,
    ): Promise<T> {
        let call: Promise<T>;

        if (!options.headers) {
            options.headers = new Headers();
        }

        if (
            this.contentType
            && !options.headers.has("Content-Type")
        ) {
            options.headers.set("Content-Type", this.contentType);
        }

        let opts = options;

        if (!options.dontApplyDefaultOptions) {
            opts = Object
                .assign({}, this.getDefaultOptions(), options);
        }

        // set request trace id
        if (!opts.skipTracing && !options.headers.has("Sk-Trace")) {
            opts.headers.append("Sk-Trace", options.trace);
        }

        const authPayload = await this.setAuthData(url, opts);

        call = this
            .request<T>(authPayload.url, authPayload.options);

        return call;
    }

    private async makePaginatedCall<K>(
        result: PaginatedResult<K>,
        url: string,
        options: PaginatedOptions,
    ): Promise<K[]> {
        const innerResult = new Result<paging.SkCursorPaginatedResponse<K>>(
            result.api,
            result.requestOptions,
            result.log,
        );

        const response = await this.makeCall(
            innerResult,
            url,
            options,
        );

        options.paginator.setFromResponse(response);

        const pageResult = options.paginator.getResultsFromResponse(response);
        result.nextPageParams = options.paginator.getNextPageParams();
        result.data = (result.data || []).concat(pageResult);

        this.log.debug("Page received", {
            endpoint: options.endpoint,
            trace: options.trace,
            count: pageResult instanceof Array
                ? pageResult.length
                : "N/A",
        });

        result.state.next({
            type: ResultStateType.PAGE_RECEIVED,
            pageResult,
        });

        if (options.paginator.hasMore()) {
            if ("nextPageRequested" in options.paginator) {
                try {
                    await options.paginator.nextPageRequested;
                } catch (e) {
                    if (e instanceof paging.PaginationCanceled) {
                        return result.data;
                    } else {
                        throw e;
                    }
                }
            }

            options.body = Object.assign({}, options.body, result.nextPageParams);
            result.state.next({
                type: ResultStateType.NEXT_PAGE,
                pageParams: result.nextPageParams,
            });

            await this.makePaginatedCall(result, url, options);
        } else {
            result.nextPageParams = null;
        }

        return result.data;
    }

    private throwClientError(err: Error, options: ClientOptions) {
        if (err instanceof http.HttpResponseError) {
            const clientError = new SimpleClientError(
                err,
                // FIXME: api should not be object (#1762)
                this.api as any,
                options.endpoint,
            );
            clientError.trace = options.trace;
            throw clientError;
        }

        if (err instanceof SkError) {
            err.trace = options.trace;
            throw err;
        }

        throw new SkErrorWrap(err, {
            trace: options.trace,
        });
    }
}

export class Result<T, O extends ClientOptions = ClientOptions> {
    public state: Subject<ResultState<T>> = new Subject<ResultState<T>>();
    public data: T;
    public error: SkError;

    private _promise: Promise<T>;

    get promise() {
        return this._promise;
    }

    set promise(promise: Promise<T>) {
        this._promise = promise
            .then<T>((response) => {
                this.data = response;

                this.log.debug("Results received", {
                    endpoint: this.requestOptions.endpoint,
                    trace: this.requestOptions.trace,
                    count: this.data instanceof Array
                        ? (<any>this.data).length
                        : "N/A",
                });

                this.state.next({
                    type: ResultStateType.RESULT_RECEIVED,
                });

                if (!this.state.isStopped) {
                    this.state.complete();
                }

                return response;
            })
            .catch((err: SkError) => {
                this.error = err;
                this.state.error(err);

                if (!this.state.isStopped) {
                    this.state.complete();
                }

                throw err;
            });
    }

    constructor(
        public readonly api: ApiObject,
        public readonly requestOptions: O,
        public readonly log: Logger,
    ) { }
}

export class PaginatedResult<T> extends Result<T[], PaginatedOptions> {
    public nextPageParams: {};
}

///
///
/// Errors
///
///

/**
 * Wrapper around HttpResponseError which offers better access to the error
 * message.
 */
export class SimpleClientError extends SkError {
    public readonly error: string = null;

    public get dataToLog() {
        return {
            api: this.api,
            endpoint: this.endpoint,
        };
    }

    public get status(): number {
        return this.originalError.status;
    }

    constructor(
        private originalError: http.HttpResponseError,
        private api: string,
        private endpoint: string,
    ) {
        super("SimpleClientError", "");

        const errorJson = this.tryToParseError();

        if (errorJson) {
            for (const errorParam of ["code", "error"]) {
                if (errorParam in errorJson) {
                    this.error = errorJson[errorParam];
                }
            }

            for (const errorMessageParam of [
                "error_description",
                "description",
                "errorMessage",
            ]) {
                if (errorMessageParam in errorJson) {
                    this._message = errorJson[errorMessageParam];
                }
            }
        }

        if (!this._message && this.originalError.status === 413) {
            this._message = "Request failed as the payload was too large.";
        }

        if (!this._message) {
            this._message = "Request failed because of unknown server error.";
        }
    }

    /**
     * Try to parse original error's response and get the error message if the
     * format resembles standard backend format.
     */
    public tryToParseError(): KnownErrorResponse | null {
        let responseJson: any = null;

        try {
            responseJson = JSON.parse(this.originalError.responseBody);
        } catch (e) {
            return null;
        }

        if (!(responseJson instanceof Object)) {
            return null;
        }

        return responseJson;
    }
}

///
///
/// Interfaces
///
///

export enum ResultStateType {
    NEXT_PAGE = "NextPage",
    PAGE_RECEIVED = "PageReceived",
    RESULT_RECEIVED = "ResultReceived",
}

export interface ClientOptions extends http.RequestOptions {
    endpoint: string;
    urlSuffix?: string;
    trace?: string;
    dontApplyDefaultOptions?: boolean;
    skipTracing?: boolean;
    skipInfoLogs?: boolean;
}

export interface PaginatedOptions extends ClientOptions {
    paginator: paging.Pagination | paging.ManualPagination;
}

export interface ResultState<T> {
    type: ResultStateType;
    pageParams?: {};
    pageResult?: T;
}

export type KnownErrorResponse = http.ErrorResponse | {
    // Auth0 format
    error_description: string,
} | {
    // Auth0 format #2 lol
    code: string;
    description: string;
};
